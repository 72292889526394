<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 服务详情 -->
    <S-DetailsNei></S-DetailsNei>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import SDetailsNei from "./base/S_DetailsNei";
 export default {
  name: "S_Details",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    SDetailsNei,
  },
};
</script>

<style scoped>
</style>
