<template>
	<div class="details">
		<!-- 广告ban -->
		<!-- <div class="kf_ban"></div> -->
		<!-- <FromList v-model="search"></FromList> -->
		<home-swiper> </home-swiper>
		<!-- 服务详情 -->
		<div class="w clearfix">
			<div class="fw_details clearfix">
				<div class="pea-title">
					<div class="one-content">
						<div class="copy-title">
							<div class="copy-con">
								<span></span>
								<h3>服务详情</h3>
								<span></span>
							</div>
						</div>
					</div>
				</div>
				<!--左 -->
				<div class="wrap-top-des left">
					<span class="left-pro-img"><img :src="urlImg + XQ.img" alt="" /></span>
					<span class="right-pro-intro">
						<div class="pro-title">{{ XQ.title }}</div>
						<p class="pro-intros" v-html="XQ.describe"><!-- {{ XQ.describe }} --></p>
						<div class="pro-reflect-time">
							<p>服务响应时间:{{ XQ.response_time }}</p>
							<p style="margin-left:30px">服务费用:{{ XQ.cost }}元</p>
							<p style="margin-left:30px">联系电话:{{ XQ.phone }}</p>
						</div>

						<div class="consult-btns">
							<div class="btn-box right"><el-button type="primary" @click="zixun(XQ.provider.id)">在线咨询</el-button></div>
						</div>
					</span>
				</div>
				<!-- 右 -->
				<div class="rg-company-intro right">
					<div class="rg-company-inner">
						<div class="top-company">
							<span class="company-logo"><img :src="urlImg + XQ.cimg" alt="" /></span>
							<span class="company-rgs">
								<div :title="XQ.company" class="company-name">{{ XQ.company }}</div>
							</span>
						</div>
						<div class="company-intro" v-html="XQ.description"><!-- {{ XQ.description }} --></div>
						<div class="bottom-come-facitator right" @click="jin(XQ.provider.id)">进入服务商主页</div>
					</div>
				</div>
			</div>
			<!-- 服务说明 -->
			<div class="center-pro-intro">
				<div class="center-pro-word">
					<h3 class="center-pro-title">
						<i></i>
						服务说明
					</h3>
					<p class="service-intro-del" v-html="XQ.explain"></p>
				</div>
				<div class="center-pro-word">
					<h3 class="center-pro-title">
						<i></i>
						服务流程
					</h3>
					<div class="all-content-eidt">
						<img v-if="XQ.process" :src="urlImg + XQ.process" alt="" />
						<div v-else style="height:100px"></div>
					</div>
				</div>
				<div class="center-pro-word">
					<h3 class="center-pro-title">
						<i></i>
						服务优势
					</h3>
					<div class="all-content-eidt all-content-eidtac">
						<img v-if="XQ.guarantee" :src="urlImg + XQ.guarantee" alt="" />
						<div v-else style="height:100px"></div>
					</div>
				</div>
				<div class="center-pro-word">
					<h3 class="center-pro-title">
						<i></i>
						服务保障
					</h3>
					<p class="service-intro-del" v-html="XQ.advantage"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SdisplayXQ } from '../../../common/js/api';
import { urlImg } from '@/common/js/url';
import FromList from '../../../common/input';
import HomeSwiper from "../../../components/common/Swiper";
export default {
	name: 'S_DetailsNei',
	components: {
		HomeSwiper,
		FromList
	},
	data() {
		return {
			input1: '',
			id: '',
			XQ: {},
			urlImg: '',
			search: ''
		};
	},
	created() {
		this.id = this.$route.query.id;
		this.getSdisplayXQ();
		this.urlImg = urlImg();
	},
	methods: {
		getSdisplayXQ() {
			SdisplayXQ({ id: this.id })
				.then(res => {
					console.log(res);
					this.XQ = res.data;
					this.XQ.cimg = this.XQ.provider.img;
					this.XQ.company = this.XQ.provider.title;
					this.XQ.description = this.XQ.provider.description;

					if (this.XQ.phone) {
						this.XQ.phone = this.XQ.phone;
					} else {
						this.XQ.phone = this.XQ.provider.phone;
					}
				})
				.catch(() => {});
		},
		// 在线咨询
		zixun(id) {
			this.$router.push({
				path: '/chat',
				query: {
					id: id
				}
			});
		},
		// 进入服务商主页
		jin(id) {
			this.$router.push({
				path: '/serviceListHome',
				query: {
					id: id
				}
			});
		}
	},
	mounted() {}
};
</script>
<style lang="less" scoped>
.details {
	background-color: rgba(248, 249, 250, 100);
	padding-bottom: 50px;
	margin-top: 135px;
	.kf_ban {
		background: url(../../../assets/img/kefuyun/fwxq1.png) no-repeat center;
		background-size: 100% 100%;
	}
	.search-content {
		top: 250px;
	}
	.wrap-top-des > span {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		color: #696969;
	}
	// 左
	.wrap-top-des {
		width: 862px;
		background-color: #fff;
		padding: 53px 28px 50px 26px;
		border-radius: 5px;
		.left-pro-img {
			width: 312px;
			height: 217px;
			img {
				display: block;
				max-height: 217px;
			}
		}
		.right-pro-intro {
			max-width: 433px;
			margin-left: 60px;
			.pro-title {
				font-size: 16px;
				color: #00050a;
				font-family: SourceHanSansSC-regular;
			}
			.pro-intros {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
				max-height: 70px;
				margin-top: 20px;
				line-height: 25px;
			}
			.pro-reflect-time {
				margin-top: 29px;
				display: flex;
			}
			.consult-btns {
				margin-top: 30px;
			}
		}
	}
	// 右
	.rg-company-intro {
		width: 318px;
		background-color: #fff;
		padding: 29px 22px 51px 26px;
		border-radius: 5px;
		.top-company {
			> span {
				display: inline-block;
			}
			.company-logo {
				vertical-align: middle;
				img {
					display: block;
					width: 60px;
					height: 60px;
				}
			}
			.company-rgs {
				margin-left: 26px;
				line-height: 1.2;
				width: 182px;
				.company-name {
					color: rgba(16, 16, 16, 100);
					font-size: 18px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
		.company-intro {
			font-size: 12px;
			margin-top: 18px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 6;
			overflow: hidden;
			max-height: 130px;
			height: 130px;
			line-height: 22px;
			text-indent: 2em;
			font-family: SourceHanSansSC-regular;
		}
		.bottom-come-facitator {
			margin-top: 10px;
			cursor: pointer;
			border-radius: 4px;
			background-color: rgba(244, 142, 67, 100);
			color: rgba(255, 255, 255, 100);
			font-size: 12px;
			text-align: center;
			font-family: Microsoft Yahei;
			line-height: 30px;
			padding: 0 10px;
		}
	}
	// 服务说明
	.center-pro-intro {
		margin-top: 26px;
		padding: 10px 24px 40px 23px;
		background-color: #fff;
		border-radius: 5px;
		.center-pro-word {
			border-bottom: 1px solid rgba(187, 187, 187, 100);
			padding-top: 28px;
			padding-bottom: 20px;
			.center-pro-title {
				color: rgba(56, 146, 235, 100);
				font-size: 20px;
				font-family: SourceHanSansSC-bold;
				font-weight: 700;
				border-left: 6px solid #3892eb;
				padding-left: 16px;
				margin-bottom: 16px;
			}
			.service-intro-del {
				padding-left: 16px;
				color: rgba(105, 105, 105, 100);
				font-size: 14px;
				font-family: SourceHanSansSC-regular;
				line-height: 1.5;
			}
			.all-content-eidt {
				width: 100%;
				// height: 120px;
			}
			// .all-content-eidtac {
			//   height: 161px;
			// }
		}
		.center-pro-word:nth-last-child(1) {
			border-bottom: 0;
		}
	}
}
@media screen and (max-width: 1200px) {
	.details{
		width: 98%;
		margin:15rem auto 0;
	}
	.w{
		width: 100%;
	}
	.search-content{
		top: 8rem !important;
		width: 80%;
	}
	.search-content .search .search-type-list .search-type-item{
		width: 8rem !important;
		height: 4rem !important;
		font-size: 1.5rem !important;
	}
	.copy-con{
		display: flex;
		align-items: center;
	}
	.copy-con h3{
		font-size: 3rem;
	}
	.details .wrap-top-des{
		width: 100%;
	}
	.details .wrap-top-des .right-pro-intro .pro-title{
		font-size: 1.8rem;
	}
	.details .wrap-top-des .right-pro-intro .pro-intros{
		font-size: 1.4rem;
		line-height: 1.9rem;
	}
	.details .wrap-top-des .right-pro-intro .pro-reflect-time{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.details .wrap-top-des .right-pro-intro .pro-reflect-time p{
		font-size: 1.4rem;
		padding-right: 2rem;
		padding-bottom: 1rem;
		margin: 0 !important;
		
	}
	.el-button{
		font-size: 1.8rem !important;
	}
	.details .wrap-top-des .right-pro-intro{
		max-width: 55%;
	}
	.details .rg-company-intro{
		width: 100%;
	}
	.details .rg-company-intro .top-company .company-logo img{
		width: 6rem;
		height: 6rem;
	}
	.details .rg-company-intro .top-company .company-rgs{
		width: 60%;
	}
	.details .rg-company-intro .top-company .company-rgs .company-name{
		font-size: 1.6rem;
	}
	.details .rg-company-intro .company-intro{
		font-size: 1.5rem;
		line-height: 2.5rem;
		height: auto;
		max-height: 80rem !important;
	}
	.details .rg-company-intro .bottom-come-facitator{
		font-size: 1.8rem;
		padding: 1rem 1.2rem;
	}
	.details .center-pro-intro .center-pro-word .center-pro-title{
		font-size: 2.4rem;
	}
	.details .center-pro-intro .center-pro-word .service-intro-del{
		font-size: 1.5rem;
		line-height: 2.5rem;
	}
}
</style>
